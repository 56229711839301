// React
import React from "react";
// Translation
import i18n from "i18next";
// React Bootstrap
import { Card, Form, Spinner } from "react-bootstrap";
// Components
import TooltipCustom from "../TooltipWrapper/TooltipWrapper";
// Styles
import styles from "./CodeEditor.module.css";
import { Title } from "@fyrstain/fhir-front-library";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateRight } from "@fortawesome/free-solid-svg-icons";

////////////////////////////////
//           Props            //
////////////////////////////////

interface OutputProps {
  isLoading?: boolean;
  outputResult?: any;
  setOutputResult?: any;
}

const Output: React.FC<OutputProps> = ({
  isLoading,
  outputResult,
  setOutputResult,
}) => {

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  const clearOutput = async () => {
    setOutputResult("");
  };

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <>
      <Card className={styles.card}>
        <Card.Header className="flexWrapSpaceBetween">
          <div className="iconCenter">
            <Title level={2} content={"Output"} />
            {isLoading && (
              <Spinner
                animation="border"
                role="status"
                className={styles.customSpinner}
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            )}
          </div>
          <div className={[styles.buttonPadding, "iconCenter"].join(" ")}>
            <TooltipCustom
              id="tooltipResetButton"
              text={i18n.t("tooltip.reset")}
            >
              <FontAwesomeIcon
                icon={faRotateRight}
                size="2x"
                className="actionIcon"
                onClick={clearOutput}
              />
            </TooltipCustom>
          </div>
        </Card.Header>

        <Form.Group className="col">
          <Card.Body className="cardBody">
            <Form.Control
              as="textarea"
              rows={22}
              name="output"
              readOnly={true}
              value={outputResult ? outputResult : i18n.t("text.outputtext")}
              className={[styles.noResizeTextArea, "form-control"].join(" ")}
            />
          </Card.Body>
        </Form.Group>
      </Card>
    </>
  );
};

export default Output;
