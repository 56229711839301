// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_homeimageIcon__dABQ5 {
    width: 35%;
    height: auto;
    margin-right: 4rem;
}

.Home_mainHomeContainer__gLLve {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60vh;
    margin-bottom: 5rem;
}`, "",{"version":3,"sources":["webpack://./src/pages/Home/Home.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".homeimageIcon {\r\n    width: 35%;\r\n    height: auto;\r\n    margin-right: 4rem;\r\n}\r\n\r\n.mainHomeContainer {\r\n    display: flex;\r\n    align-items: center;\r\n    justify-content: center;\r\n    height: 60vh;\r\n    margin-bottom: 5rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"homeimageIcon": `Home_homeimageIcon__dABQ5`,
	"mainHomeContainer": `Home_mainHomeContainer__gLLve`
};
export default ___CSS_LOADER_EXPORT___;
