// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CodeEditor_main__Luqa0 {
  width: 80%;
  margin: auto;
}

.CodeEditor_card__uvYKo {
  margin-bottom: 3rem;
  width: 100%;
}

.CodeEditor_formTextLabel__hiTCb {
  text-align: justify;
  line-height: 1.2rem;
}

.CodeEditor_formLabel__TyP98 {
  margin: 0 1rem 1rem 0;
  text-align: justify;
  line-height: 1.2rem;
}

.CodeEditor_buttonPadding__TL61H {
  display: flex;
  gap: 1rem;
}

.CodeEditor_formText__YuLql {
  margin-bottom: 0.25rem;
}

.CodeEditor_noResizeTextArea__-xxOw {
  resize: none;
  height: 75vh;
  width: 100%;
}
.CodeEditor_customSpinner__wsWRR {
  width: 2rem !important;
  height: 2rem !important;
}
.CodeEditor_iconCenter__YXcab {
  display: flex;
  align-items: center;
  justify-content: center;
}
.CodeEditor_spinnerCenter__-76OK {
  display: flex;
  align-items: center;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/CodeEditor/CodeEditor.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAS;AACX;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;AACA;EACE,sBAAsB;EACtB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".main {\r\n  width: 80%;\r\n  margin: auto;\r\n}\r\n\r\n.card {\r\n  margin-bottom: 3rem;\r\n  width: 100%;\r\n}\r\n\r\n.formTextLabel {\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n\r\n.formLabel {\r\n  margin: 0 1rem 1rem 0;\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n\r\n.buttonPadding {\r\n  display: flex;\r\n  gap: 1rem;\r\n}\r\n\r\n.formText {\r\n  margin-bottom: 0.25rem;\r\n}\r\n\r\n.noResizeTextArea {\r\n  resize: none;\r\n  height: 75vh;\r\n  width: 100%;\r\n}\r\n.customSpinner {\r\n  width: 2rem !important;\r\n  height: 2rem !important;\r\n}\r\n.iconCenter {\r\n  display: flex;\r\n  align-items: center;\r\n  justify-content: center;\r\n}\r\n.spinnerCenter {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `CodeEditor_main__Luqa0`,
	"card": `CodeEditor_card__uvYKo`,
	"formTextLabel": `CodeEditor_formTextLabel__hiTCb`,
	"formLabel": `CodeEditor_formLabel__TyP98`,
	"buttonPadding": `CodeEditor_buttonPadding__TL61H`,
	"formText": `CodeEditor_formText__YuLql`,
	"noResizeTextArea": `CodeEditor_noResizeTextArea__-xxOw`,
	"customSpinner": `CodeEditor_customSpinner__wsWRR`,
	"iconCenter": `CodeEditor_iconCenter__YXcab`,
	"spinnerCenter": `CodeEditor_spinnerCenter__-76OK`
};
export default ___CSS_LOADER_EXPORT___;
