// React
import { FunctionComponent } from "react";
// Styles
import styles from "./Error.module.css";
// Fhir Front Library
import { Title } from "@fyrstain/fhir-front-library";
// Components
import PolusPage from "../../components/PolusPage/PolusPage";
// Translation
import i18n from "i18next";

const Error: FunctionComponent = () => {
  return (
    <PolusPage loading={false} fitFooter={true} needsLogin={false}>
      <div className={styles.mainErrorContainer}>
        <img
          className={styles.imageIcon}
          src="../assets/oops.png"
          alt="Oops !"
        />
        <Title
          level={1}
          prefix={i18n.t("title.error.oops")}
          content={i18n.t("title.error.problem")}
        />
      </div>
    </PolusPage>
  );
};

export default Error;
