// React
import { FunctionComponent } from "react";
// React BootStrap
import { OverlayTrigger, Tooltip } from "react-bootstrap";

////////////////////////////////
//           Props            //
////////////////////////////////

interface TooltipWrapperProps {
  id: string;
  text?: string;
  children: any;
}

const TooltipWrapper: FunctionComponent<TooltipWrapperProps> = ({
  id,
  text,
  children,
}) => {
    
  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    // To use a tooltip, an OverlayTrigger need an ID
    <OverlayTrigger overlay={<Tooltip id={id}>{text}</Tooltip>}>
      {children}
    </OverlayTrigger>
  );
};

export default TooltipWrapper;
