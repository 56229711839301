// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cql_main__vimo2 {
  width: 80%;
  margin: auto;
}
.Cql_card__i1qD0 {
  margin-bottom: 3rem;
  width: 100%;
}
.Cql_formTextLabel__H1YjR {
  text-align: justify;
  line-height: 1.2rem;
}
.Cql_formLabel__t7mTE {
  margin: 0 1rem 1rem 0;
  text-align: justify;
  line-height: 1.2rem;
}
.Cql_badgeContainer__iKdCH {
  width: 100%;
}
.Cql_tagMargin__nnYl0 {
  padding: 0 0 0 1rem;
  margin-top: 0rem !important;
}
.Cql_buttonPadding__q-l2- {
  display: flex;
  gap: 1rem;
}
.Cql_formText__tKzGf {
  margin-bottom: 0.25rem;
}
.Cql_noResizeTextArea__UTJhj {
  resize: none;
  height: 75vh;
  width: 100%;
}
.Cql_flexStartAlertIcon__YVuNt {
  display: flex;
  align-items: center;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Cql/Cql.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,WAAW;AACb;AACA;EACE,mBAAmB;EACnB,2BAA2B;AAC7B;AACA;EACE,aAAa;EACb,SAAS;AACX;AACA;EACE,sBAAsB;AACxB;AACA;EACE,YAAY;EACZ,YAAY;EACZ,WAAW;AACb;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".main {\r\n  width: 80%;\r\n  margin: auto;\r\n}\r\n.card {\r\n  margin-bottom: 3rem;\r\n  width: 100%;\r\n}\r\n.formTextLabel {\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n.formLabel {\r\n  margin: 0 1rem 1rem 0;\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n.badgeContainer {\r\n  width: 100%;\r\n}\r\n.tagMargin {\r\n  padding: 0 0 0 1rem;\r\n  margin-top: 0rem !important;\r\n}\r\n.buttonPadding {\r\n  display: flex;\r\n  gap: 1rem;\r\n}\r\n.formText {\r\n  margin-bottom: 0.25rem;\r\n}\r\n.noResizeTextArea {\r\n  resize: none;\r\n  height: 75vh;\r\n  width: 100%;\r\n}\r\n.flexStartAlertIcon {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `Cql_main__vimo2`,
	"card": `Cql_card__i1qD0`,
	"formTextLabel": `Cql_formTextLabel__H1YjR`,
	"formLabel": `Cql_formLabel__t7mTE`,
	"badgeContainer": `Cql_badgeContainer__iKdCH`,
	"tagMargin": `Cql_tagMargin__nnYl0`,
	"buttonPadding": `Cql_buttonPadding__q-l2-`,
	"formText": `Cql_formText__tKzGf`,
	"noResizeTextArea": `Cql_noResizeTextArea__UTJhj`,
	"flexStartAlertIcon": `Cql_flexStartAlertIcon__YVuNt`
};
export default ___CSS_LOADER_EXPORT___;
