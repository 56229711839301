// React
import React from "react";
// Monaco (Editor) & languages
import Editor from "@monaco-editor/react";
import { CQLLanguage } from "../../languages/CQLLanguage";
// Translation
import i18n from "i18next";

////////////////////////////////
//           Props            //
////////////////////////////////

interface CodeEditorProps {
  libraryContent: string;
  setLibraryContent: any;
  editorRef: any;
}

const CodeEditor: React.FC<CodeEditorProps> = ({
  libraryContent,
  setLibraryContent,
  editorRef,
}) => {
    
  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  const onMount = (editor: any) => {
    editorRef.current = editor;
    editor.focus();
  };

  function beforeMount(monaco: any) {
    monaco.languages.register({ id: "cql" });
    monaco.languages.setMonarchTokensProvider("cql", CQLLanguage);
  }

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <>
      <Editor
        height="75vh"
        theme="vs-light"
        defaultLanguage="cql"
        defaultValue={i18n.t("text.editortext")}
        value={libraryContent}
        beforeMount={beforeMount}
        onMount={onMount}
        onChange={(value: any) => setLibraryContent(value)}
        options={{
          // Disable autocomplete
          quickSuggestions: false,
          // Disable suggestions on trigger characters
          suggestOnTriggerCharacters: false,
          // Disable minimap
          minimap: {
            enabled: false,
          },
        }}
      />
    </>
  );
};

export default CodeEditor;
