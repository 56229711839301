// React
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
// Components
import PolusTestablePage from "../../components/PolusTestablePage/PolusTestablePage";
import CodeEditor from "../../components/CodeEditor/CodeEditor";
import Output from "../../components/CodeEditor/Output";
import ConfigModal from "../../components/ConfigModal/ConfigModal";
import TooltipCustom from "../../components/TooltipWrapper/TooltipWrapper";
// Fhir Front Library
import { FhirStatus, StatusTag, Title } from "@fyrstain/fhir-front-library";
// FHIR
import Client from "fhir-kit-client";
// Resources FHIR
import { Library } from "fhir/r4";
import { Parameters } from "fhir/r4";
// Translation
import i18n from "i18next";
// React Bootstrap
import { Alert, Card, Form } from "react-bootstrap";
// Navigation
import { useNavigate, useParams } from "react-router-dom";
// Styles
import styles from "./Cql.module.css";
// Buffer
import { Buffer } from "buffer";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSave,
  faGear,
  faPlay,
  faCircleCheck,
  faBan,
} from "@fortawesome/free-solid-svg-icons";

const Cql: FunctionComponent = () => {

  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Library informations
  const { libraryId } = useParams();
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [description, setDescription] = useState("");

  // CodeEditor
  const editorRef = useRef(null);

  // Library configurations 
  const [config, setConfig] = useState({
    engineUrl: process.env.REACT_APP_ENGINE_URL ?? "",
    patientId: "PATIENT_123",
    dataSource: process.env.REACT_APP_REMOTE_FHIR_URL ?? "",
    terminologyService: process.env.REACT_APP_REMOTE_FHIR_URL ?? "",
    librarySourceUrl: process.env.REACT_APP_REMOTE_FHIR_URL ?? "",
  });
  const baseURL = process.env.REACT_APP_FHIR_URL;

  // Library contents and results
  const [libraryContent, setLibraryContent] = useState("");
  const [libraryResource, setLibraryResource] = useState({} as Library);
  const [outputResult, setOutputResult] = useState<string | null>(null);
  
  // Show Alert
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showFailAlert, setShowFailAlert] = useState(false);
  
  // Show the modal 
  const [showModal, setShowModal] = useState(false);
  
  // Show the spinner if the user clicked on the play button
  const [isLoading, setIsLoading] = useState(false);

  /////////////////////////////////////
  //             Client              //
  /////////////////////////////////////

  const fhirClient = new Client({
    baseUrl: baseURL ?? "fhir",
  });

  const fhirEvaluateClient = new Client({
    baseUrl: baseURL ?? "fhir",
  });

  //////////////////////////////
  //           Error          //
  //////////////////////////////

  const onError = useCallback(() => {
    navigate("/Error");
  }, [navigate]);

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  useEffect(() => {
    load();
  }, []);

  async function load() {
    setLoading(true);
    await loadLibrary();
    setLoading(false);
  }

  /**
   * Load Library from the back to populate the fields.
   *
   * @returns the promise of a Library.
   */
  async function loadLibrary() {
    try {
      const response = await fhirClient.read({
        resourceType: "Library",
        id: libraryId ?? "",
      });
      if (response.resourceType !== "Library") {
        throw Error(response.statusText);
      }
      const library: Library = response as Library;
      setName(library.title ? library.title : "N/A");
      setDescription(library.description ? library.description : "N/A");
      setStatus(library.status ? library.status : "N/A");
      setLibraryResource(library);
      setLibraryContent(
        Buffer.from(
          library.content?.map((c) => c.data).find((d) => d) ?? "",
          "base64"
        ).toString()
      );
    } catch (error) {
      onError();
    }
    return [];
  }

  /**
   * Function to close the success alert after 6s
   */
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showSuccessAlert) {
      timeout = setTimeout(() => setShowSuccessAlert(false), 6000);
    }
    return () => clearTimeout(timeout);
  }, [showSuccessAlert]);

  /**
   * Function to close the fail alert after 6s
   */
  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (showFailAlert) {
      timeout = setTimeout(() => setShowFailAlert(false), 6000);
    }
    return () => clearTimeout(timeout);
  }, [showFailAlert]);

  /**
   * Function to save library with PUT(update)
   */
  async function onSave() {
    const base64 = Buffer.from(libraryContent).toString("base64");
    const content = libraryResource.content?.find((c) => c);
    if (content) {
      content.data = base64;
    }
    try {
      await fhirClient.update({
        resourceType: "Library",
        id: libraryId ?? "",
        body: libraryResource,
      });
      setShowSuccessAlert(true);
    } catch (error) {
      onError();
      setShowFailAlert(true);
    }
  }

  /**
   * Function to run the code to Output from Input
   */
  const runCode = async () => {
    setIsLoading(true);
    const parameters: Parameters = {
      resourceType: "Parameters",
      parameter: [
        {
          name: "dataEndpoint",
          resource: {
            resourceType: "Endpoint",
            status: "active",
            connectionType: {
              system:
                "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
              code: "hl7-fhir-rest",
            },
            payloadType: [
              {
                coding: [
                  {
                    system:
                      "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
                    code: "hl7-fhir-rest",
                    display: "HL7 FHIR",
                  },
                ],
              },
            ],
            address: config.dataSource,
            header: ["Content-Type: application/json"],
          },
        },
        {
          name: "terminologyEndpoint",
          resource: {
            resourceType: "Endpoint",
            status: "active",
            connectionType: {
              system:
                "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
              code: "hl7-fhir-rest",
            },
            payloadType: [
              {
                coding: [
                  {
                    system:
                      "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
                    code: "hl7-fhir-rest",
                    display: "HL7 FHIR",
                  },
                ],
              },
            ],
            address: config.terminologyService,
            header: ["Content-Type: application/json"],
          },
        },
        {
          name: "contentEndpoint",
          resource: {
            resourceType: "Endpoint",
            status: "active",
            connectionType: {
              system:
                "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
              code: "hl7-fhir-rest",
            },
            payloadType: [
              {
                coding: [
                  {
                    system:
                      "http://terminology.hl7.org/CodeSystem/endpoint-connection-type",
                    code: "hl7-fhir-rest",
                    display: "HL7 FHIR",
                  },
                ],
              },
            ],
            address: config.librarySourceUrl,
            header: ["Content-Type: application/json"],
          },
        },
        {
          name: "subject",
          valueString: config.patientId,
        },
      ],
    };
    await callEvaluate(parameters);
  };

  /**
   * Call Evaluate from the back for the resource Library.
   *
   * @param parameters The parameters to pass - $evaluate operation.
   */
  function callEvaluate(parameters: Parameters) {
    fhirEvaluateClient.baseUrl =
      config.engineUrl && config.engineUrl.length > 0
        ? config.engineUrl
        : baseURL ?? "/fhir";
    fhirEvaluateClient
      .operation({
        resourceType: "Library",
        name: "$evaluate",
        id: libraryId,
        method: "POST",
        input: parameters,
      })
      .then((result) => {
        setOutputResult(JSON.stringify(result, null, "\t"));
        setIsLoading(false);
      })
      .catch((error) => {
        setOutputResult(error);
        setIsLoading(false);
      });
  }

  return (
    <PolusTestablePage
      titleKey="title.cql"
      loading={loading}
      needsLogin={true}
      urlTestable={libraryResource.url}
      testUrl={process.env.REACT_APP_TEST_URL}
      testServerUrl={process.env.REACT_APP_TEST_SERVER_URL}
      serverId={process.env.REACT_APP_R4_SERVER_ID}
      clientId={process.env.REACT_APP_R4_CLIENT_ID}
    >
      <>
        {/* INFORMATION */}
        <div className="section">
          <Card className={styles.card}>
            <Card.Header>
              <Title level={2} content="Informations" />
            </Card.Header>

            <Card.Body className="cardBody flexWrap">
              <div className={styles.form}>
                <div className={styles.formTextLabel}>
                  <Form.Label className={styles.formLabel}>
                    <strong className={styles.label}>ID :</strong>
                  </Form.Label>
                  <Form.Text>{libraryId}</Form.Text>
                </div>

                <div className={styles.formTextLabel}>
                  <Form.Label className={styles.formLabel}>
                    <strong className={styles.label}>
                      {i18n.t("label.name")} :
                    </strong>
                  </Form.Label>
                  <Form.Text>{name}</Form.Text>
                </div>

                <div className={[styles.badgeContainer, "flexWrap"].join(" ")}>
                  <Form.Label className={styles.statuslabel}>
                    <strong>{i18n.t("label.status")} :</strong>
                  </Form.Label>
                  <Form.Text
                    className={[styles.formText, styles.tagMargin].join(" ")}
                  >
                    <StatusTag
                      status={FhirStatus[status as keyof typeof FhirStatus]}
                      statusMessage={status}
                    />
                  </Form.Text>
                </div>

                <div className={styles.formTextLabel}>
                  <Form.Label className={styles.formLabel}>
                    <strong className={styles.label}>
                      {i18n.t("label.generaldescription")} :
                    </strong>
                  </Form.Label>
                  <Form.Text>{description}</Form.Text>
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        {/* ALERTS (success and failure) */}
        <div className={styles.alert}>
          {showSuccessAlert && (
            <Alert
              variant="success"
              onClose={() => setShowSuccessAlert(false)}
              dismissible
            >
              <Alert.Heading>
                <div className={styles.flexStartAlertIcon}>
                  <FontAwesomeIcon icon={faCircleCheck} />
                  {i18n.t("text.savecqlsuccessheader")}
                </div>
              </Alert.Heading>
              {i18n.t("text.savecqlsuccess")}
            </Alert>
          )}

          {showFailAlert && (
            <Alert
              variant="danger"
              onClose={() => setShowFailAlert(false)}
              dismissible
            >
              <Alert.Heading>
                <div className={styles.flexStartAlertIcon}>
                  <FontAwesomeIcon icon={faBan} />
                  {i18n.t("text.savecqlfailheader")}
                </div>
              </Alert.Heading>{" "}
              {i18n.t("text.savecqlfail")}
            </Alert>
          )}
        </div>

        {/* CQL IDE - CQL ENGINE */}
        <div className="section">
          <div className="displayFlexCenter">
            {/* INPUT */}
            <Card className={styles.card}>
              <Card.Header className="flexWrapSpaceBetween">
                <Title level={2} content={"Input"} />
                <div className={[styles.buttonPadding, "iconCenter"].join(" ")}>
                  <TooltipCustom
                    id="tooltipSaveButton"
                    text={i18n.t("tooltip.save")}
                  >
                    <FontAwesomeIcon
                      icon={faSave}
                      size="2x"
                      onClick={onSave}
                      className="actionIcon"
                    />
                  </TooltipCustom>
                  <TooltipCustom
                    id="tooltipSaveButton"
                    text={i18n.t("tooltip.configuration")}
                  >
                    <FontAwesomeIcon
                      icon={faGear}
                      size="2x"
                      onClick={() => setShowModal(true)}
                      className="actionIcon"
                    />
                  </TooltipCustom>
                  <ConfigModal
                    config={config}
                    setConfig={setConfig}
                    show={showModal}
                    onHide={() => setShowModal(false)}
                  />
                  <TooltipCustom
                    id="tooltipSaveButton"
                    text={i18n.t("tooltip.play")}
                  >
                    <FontAwesomeIcon
                      icon={faPlay}
                      size="2x"
                      className="actionIcon"
                      onClick={runCode}
                    />
                  </TooltipCustom>
                </div>
              </Card.Header>

              <Card.Body className="cardBody">
                <CodeEditor
                  libraryContent={libraryContent}
                  setLibraryContent={setLibraryContent}
                  editorRef={editorRef}
                />
              </Card.Body>
            </Card>

            {/* OUTPUT */}
            <Output
              isLoading={isLoading}
              outputResult={outputResult}
              setOutputResult={setOutputResult}
            />
          </div>
        </div>
      </>
    </PolusTestablePage>
  );
};

export default Cql;
