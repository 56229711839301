// React
import { FunctionComponent } from "react";
// Components
import PolusPage from "../../components/PolusPage/PolusPage";

const EditLibrary: FunctionComponent = () => {
    return (
        <PolusPage titleKey='title.editlibrary' loading={false} fitFooter={true} needsLogin={true}>
            <>
                Add content here - EditLibrary
            </>
        </PolusPage>
    );
};

export default EditLibrary;