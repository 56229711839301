// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ConfigModal_main__bLjdM {
  width: 80%;
  margin: auto;
}

.ConfigModal_card__CB\\+5O {
  margin-bottom: 3rem;
}

.ConfigModal_formSelect__L4Z20 {
  margin: 0 2rem 0 1.5rem;
  width: 100%;
}

.ConfigModal_formTextLabel__xeeGX {
  margin: 0 1rem 0 1.5rem;
  text-align: justify;
  line-height: 1.2rem;
}

.ConfigModal_formLabel__v-QvD {
  margin: 0 1rem 1rem 0;
  text-align: justify;
  line-height: 1.2rem;
}
.ConfigModal_flexStartAlertIcon__E0gf3 {
  display: flex;
  align-items: center;
  gap: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/ConfigModal/ConfigModal.module.css"],"names":[],"mappings":"AAAA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,mBAAmB;EACnB,mBAAmB;AACrB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,SAAS;AACX","sourcesContent":[".main {\r\n  width: 80%;\r\n  margin: auto;\r\n}\r\n\r\n.card {\r\n  margin-bottom: 3rem;\r\n}\r\n\r\n.formSelect {\r\n  margin: 0 2rem 0 1.5rem;\r\n  width: 100%;\r\n}\r\n\r\n.formTextLabel {\r\n  margin: 0 1rem 0 1.5rem;\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n\r\n.formLabel {\r\n  margin: 0 1rem 1rem 0;\r\n  text-align: justify;\r\n  line-height: 1.2rem;\r\n}\r\n.flexStartAlertIcon {\r\n  display: flex;\r\n  align-items: center;\r\n  gap: 1rem;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": `ConfigModal_main__bLjdM`,
	"card": `ConfigModal_card__CB+5O`,
	"formSelect": `ConfigModal_formSelect__L4Z20`,
	"formTextLabel": `ConfigModal_formTextLabel__xeeGX`,
	"formLabel": `ConfigModal_formLabel__v-QvD`,
	"flexStartAlertIcon": `ConfigModal_flexStartAlertIcon__E0gf3`
};
export default ___CSS_LOADER_EXPORT___;
