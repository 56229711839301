import { languages } from "monaco-editor";

export const CQLLanguage: languages.IMonarchLanguage = {
  defaultToken: "",
  tokenPostfix: ".cql",

  tokenizer: {
    root: [
      // Keywords
      [
        /\b(concept|code|context|after|all|and|as|asc|ascending|before|between|by|called|case|cast|codesystem|codesystems|collapse|contains|convert|day|days|default|define|desc|descending|difference|display|distinct|div|duration|during|else|end|ends|except|exists|flatten|from|function|hour|hours|if|implies|in|include|includes|intersect|is|let|library|maximum|meets|millisecond|milliseconds|minimum|minute|minutes|mod|month|months|not|occurs|of|or|overlaps|parameter|predecessor|private|public|return|same|singleton|second|seconds|start|starts|successor|then|to|union|using|valueset|version|week|weeks|where|when|with|within|without|xor|year|years|Any|Boolean|Code|Concept|DateTime|Decimal|Integer|Interval|List|Quantity|String|Time|Tuple|AllTrue|AnyTrue|Avg|Count|Max|Min|Median|Mode|PopulationStdDev|PopulationVariance|StdDev|Sum|Variance|First|IndexOf|Last|Length|Now|TimeOfDay|Today|Combine|Length|Lower|PositionOf|Split|Substring|Upper|Abs|Ceiling|Floor|Log|Ln|Round|Truncate|ToBoolean|ToConcept|ToDateTime|ToDecimal|ToInteger|ToQuantity|ToString|ToTime)\b/i,
        "keyword",
      ],

      // Strings
      [/"([^"\\]|\\.)*"/, "string"], // double-quoted strings
      [/'([^'\\]|\\.)*'/, "single-string"], // single-quoted strings

      // Numbers
      [/\d+/, "number"],

      // Comments
      [/\/\*/, "comment", "@comment"], // start multi-line comment
      [/\/\//, "comment", "@lineComment"], // start single-line comment
    ],

    comment: [
      [/[^\/*]+/, "comment"], // comment content
      [/\*\//, "comment", "@pop"], // end multi-line comment
      [/[\/*]/, "comment"], // characters within the comment
    ],

    lineComment: [
      [/.$/, "comment", "@pop"], // end single-line comment
      [/[^]/, "comment"], // content of single-line comment
    ],
  },
};

// Register the CQL language
languages.register({ id: "cql" });
languages.setMonarchTokensProvider("cql", CQLLanguage);
