// React
import { FunctionComponent, useCallback } from "react";
// Font awesome
import { faPlay, faPen } from "@fortawesome/free-solid-svg-icons";
// Fhir front library
import {
  FhirStatus,
  SearchableTable,
  StatusTag,
} from "@fyrstain/fhir-front-library";
// Translation
import i18n from "i18next";
// Navigation
import { useNavigate } from "react-router-dom";
// Components
import PolusPage from "../../components/PolusPage/PolusPage";

const Libraries: FunctionComponent = () => {
    
  //////////////////////////////
  //        Navigation        //
  //////////////////////////////

  const navigate = useNavigate();

//   const onDetails = useCallback(
//     (id: string) => {
//       navigate("/Edit/" + id);
//     },
//     [navigate]
//   );

  const viewInProgress = useCallback(() => {
    navigate("/InProgress");
  }, [navigate]);

  const onLaunch = useCallback(
    (id: string) => {
      navigate("/Cql/" + id);
    },
    [navigate]
  );

  //////////////////////////////
  //           Error          //
  //////////////////////////////

  const onError = useCallback(() => {
    navigate("/Error");
  }, [navigate]);

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <PolusPage titleKey="title.libraries" needsLogin={true}>
      <SearchableTable
        searchCriteriaProps={{
          title: i18n.t("title.searchcriteria"),
          primaryButtonLabel: i18n.t("button.search"),
          secondaryButtonLabel: i18n.t("button.reset"),
          language: i18n.t,
          searchResultModifier: {
            _elements: "id,title,status",
            _sort: "-_lastUpdated",
          },
          criteria: [
            {
              inputProps: {
                label: "ID",
                type: "text",
              },
              searchParamsName: "_id",
            },
            {
              inputProps: {
                label: i18n.t("label.name"),
                type: "text",
              },
              searchParamsName: "title:contains",
            },
            {
              selectProps: {
                value: null,
                defaultValue: i18n.t("defaultvalue.status"),
                options: [
                  { value: "draft", label: i18n.t("label.draft") },
                  { value: "active", label: i18n.t("label.active") },
                  { value: "retired", label: i18n.t("label.retired") },
                  { value: "unknown", label: i18n.t("label.unknown") },
                ],
              },
              searchParamsName: "status",
            },
          ],
        }}
        paginationTableProps={{
          columns: [
            {
              header: "ID",
              dataField: "id",
              width: "25%",
            },
            {
              header: i18n.t("label.name"),
              dataField: "Name",
              width: "40%",
            },
            {
              header: i18n.t("label.status"),
              dataField: "Status",
              width: "20%",
              formatter: (cell: keyof typeof FhirStatus) => {
                return (
                  <StatusTag status={FhirStatus[cell]} statusMessage={cell} />
                );
              },
            },
          ],
          action: [
            {
              icon: faPen,
              onClick: viewInProgress,
            },
            {
              icon: faPlay,
              onClick: onLaunch,
            },
          ],
          mapResourceToData: (resource: any) => {
            return {
              id: resource.id,
              Name: resource.title ? resource.title : "N/A",
              Status: resource.status,
            };
          },
          searchProps: {
            serverUrl: process.env.REACT_APP_FHIR_URL ?? "fhir",
            resourceType: "Library",
          },
          onError: onError,
        }}
      />
    </PolusPage>
  );
};

export default Libraries;
