// React
import { FunctionComponent } from "react";
// Library
import { Title } from "@fyrstain/fhir-front-library";
// Components
import PolusPage from "../../components/PolusPage/PolusPage";
// Styles
import styles from "./Home.module.css";
// Import for the translation
import i18n from "i18next";

const Home: FunctionComponent = () => {
    return (
        <PolusPage loading={false} fitFooter={true} needsLogin={false}>
            <>
                <div className={styles.mainHomeContainer}>
                    <img
                        className={styles.homeimageIcon}
                        alt="Home_image"
                        src="/assets/home.jpg"
                    />
                    <Title level={1} prefix={'Polus'} content={i18n.t('title.librariesandcql')}/>
                </div>
            </>
        </PolusPage>
    );
};

export default Home;
