// React
import { useState } from "react";
// Translation
import i18n from "i18next";
//React Bootstrap
import { Button, Form, Modal } from "react-bootstrap";
// FontAwesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/free-solid-svg-icons";
// Styles
import styles from "./ConfigModal.module.css";

////////////////////////////////
//           Props            //
////////////////////////////////

interface ConfigProps {
  config: config;
  setConfig: (config: ConfigProps["config"]) => void;
  show: boolean;
  onHide: (value: boolean) => void;
}

////////////////////////////////
//         Interfaces         //
////////////////////////////////

interface config {
  engineUrl: any;
  patientId: string;
  dataSource: string;
  terminologyService: string;
  librarySourceUrl: string;
}

const ConfigModal: React.FC<ConfigProps> = (props: ConfigProps) => {

  /////////////////////////////////////
  //      Constants / ValueSet       //
  /////////////////////////////////////

  const { config, setConfig, show, onHide } = props;
  const [conftemp, setConfigTemp] = useState({ ...config });

  ////////////////////////////////
  //           Actions          //
  ////////////////////////////////

  const handleClose = () => {
    onHide(false);
    setConfigTemp(config);
  };

  const validate = () => {
    setConfig(conftemp);
    onHide(false);
  };

  //////////////////////////////
  //          Content         //
  //////////////////////////////

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        hide
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {/* HEADER */}
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <h2 className={styles.flexStartAlertIcon}>
              {i18n.t("title.cqlconfig")}
              <FontAwesomeIcon icon={faGear} />
            </h2>
          </Modal.Title>
        </Modal.Header>

        {/* BODY */}
        <Modal.Body>
          <div className={styles.formTextLabel}>
            <Form>
              {/* CQL ENGINE */}
              <Form.Group className="mb-3">
                <Form.Label className={styles.formLabel}>
                  <h5>{i18n.t("modal.cqlengine")}</h5>
                </Form.Label>
                <Form.Control
                  type="text"
                  autoFocus
                  value={conftemp.engineUrl}
                  onChange={(e) =>
                    setConfigTemp({ ...conftemp, engineUrl: e.target.value })
                  }
                />
              </Form.Group>

              {/* PATIENT ID */}
              <Form.Group className="mb-3">
                <Form.Label className={styles.formLabel}>
                  <h5>{i18n.t("modal.patientid")}</h5>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={conftemp.patientId}
                  onChange={(e) =>
                    setConfigTemp({ ...conftemp, patientId: e.target.value })
                  }
                />
              </Form.Group>

              {/* DATA SOURCE */}
              <Form.Group className="mb-3">
                <Form.Label className={styles.formLabel}>
                  <h5>{i18n.t("modal.datasource")}</h5>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={conftemp.dataSource}
                  onChange={(e) =>
                    setConfigTemp({ ...conftemp, dataSource: e.target.value })
                  }
                />
              </Form.Group>

              {/* TERMINOLOGY SERVICE */}
              <Form.Group className="mb-3">
                <Form.Label className={styles.formLabel}>
                  <h5>{i18n.t("modal.terminologyservice")}</h5>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={conftemp.terminologyService}
                  onChange={(e) =>
                    setConfigTemp({
                      ...conftemp,
                      terminologyService: e.target.value,
                    })
                  }
                />
              </Form.Group>

              {/* LIBRARY SOURCE */}
              <Form.Group className="mb-3">
                <Form.Label className={styles.formLabel}>
                  <h5>{i18n.t("modal.librarysource")}</h5>
                </Form.Label>
                <Form.Control
                  type="text"
                  value={conftemp.librarySourceUrl}
                  onChange={(e) =>
                    setConfigTemp({
                      ...conftemp,
                      librarySourceUrl: e.target.value,
                    })
                  }
                  disabled
                />
              </Form.Group>
            </Form>
          </div>
        </Modal.Body>

        {/* FOOTER */}
        <Modal.Footer>
          <Button className="button" variant="danger" onClick={validate}>
            {i18n.t("button.validate")}
          </Button>
          <Button className="button" variant="secondary" onClick={handleClose}>
            {i18n.t("button.cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfigModal;
